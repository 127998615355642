/*
 * App Global CSS
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Dark mode support */
@import "@ionic/angular/css/palettes/dark.always.css";

/* Global styles */
:root {
  --ion-font-family: 'Montserrat', sans-serif;
  
  /* Force dark theme variables */
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;
  --ion-border-color: #2f2f2f;
  
  /* Dark theme specific colors */
  --ion-color-primary: #2f2f2f;
  --ion-color-primary-rgb: 47,47,47;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #292929;
  --ion-color-primary-tint: #444444;
}

/* Force dark theme styles */
ion-content {
//   --background: var(--ion-background-color);
  background  : linear-gradient(to bottom, #2f2e2e, #393b40) !important;
}

ion-card {
  --background: #1e1e1e;
  --color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--ion-border-color);
}

ion-item {
  --background: #1e1e1e;
  --color: #ffffff;
  --border-color: #2f2f2f;
}

ion-toolbar {
  --background: #1e1e1e;
  --color: #ffffff;
}
