// Ionic Variables and Theming

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #171717;
  --ion-color-primary-rgb: 44, 85, 48;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5f0153;
  --ion-color-primary-tint: #3f0027;

  /** secondary **/
  --ion-color-secondary: #4a7a50;
  --ion-color-secondary-rgb: 74, 122, 80;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #416b46;
  --ion-color-secondary-tint: #5c8762;

  /** tertiary **/
  --ion-color-tertiary: #a3b899;
  --ion-color-tertiary-rgb: 163, 184, 153;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #8fa287;
  --ion-color-tertiary-tint: #acbfa3;

  /** success **/
  --ion-color-success: #38db8f;
  --ion-color-success-rgb: 117, 183, 152;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #24a469;
  --ion-color-success-tint: #85f3c0;

  /** warning **/
  --ion-color-warning: #e6c78c;
  --ion-color-warning-rgb: 230, 199, 140;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #cab07b;
  --ion-color-warning-tint: #e9cd98;

  /** danger **/
  --ion-color-danger: #cf8e8e;
  --ion-color-danger-rgb: 207, 142, 142;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #b67d7d;
  --ion-color-danger-tint: #d49999;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** dark **/
  --ion-color-dark: #27272A;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** Background and text colors for light theme **/
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0;
  --ion-border-color: #d8d8d8;
  --ion-item-background: #ffffff;
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #ffffff;
  --ion-card-background: #ffffff;

  /* Tab colors */
  --tab-evento-color: #62d1e2;
  --tab-fotos-color: #0bed65;
  --tab-tops-color: #f9c700;
  --tab-players-color: #e21960;
  --tab-dedicatoria-color: #9259e7;
}

:root {
  ion-card {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--border-color);
  }

  ion-item {
    --border-color: #d8d8d8;
  }

  ion-content {
    --background: #f4f5f8;
  }

  ion-toolbar {
    --background: var(--ion-color-primary);
    --color: #ffffff;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    /** Dark theme variables **/
    --ion-color-primary: #2f2f2f;
    --ion-color-primary-rgb: 47, 47, 47;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #292929;
    --ion-color-primary-tint: #444444;

    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #2f2f2f;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: var(--ion-color-primary);
    --ion-toolbar-color: #ffffff;
    --ion-card-background: #1e1e1e;

    ion-card {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      border: 1px solid var(--ion-border-color);
    }

    ion-item {
      --border-color: #2f2f2f;
    }

    ion-content {
      --background: var(--ion-background-color);
    }

    ion-toolbar {
      --background: var(--ion-color-primary);
      --color: #ffffff;
    }
  }
}
